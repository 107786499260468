import { NotificationService } from '../../services/notification.service'
import { Notification } from '../../types/notifications/notification-types'
import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { Subject } from 'rxjs'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { FileStorageService } from '../../services/file-storage.service'

@Component({
    selector: 'app-notification-details',
    templateUrl: './notification-details.component.html',
    styleUrls: ['./notification-details.component.scss'],
})
export class NotificationDetailsComponent implements OnChanges {
    @Input() notification!: Notification
    @Input() title!: string
    @Input() opened!: boolean
    @Output() openedChange = new Subject<boolean>()

    youtubeUrl: SafeResourceUrl | null = null
    publicLinkImg: string = ''

    constructor(
        public sanitizer: DomSanitizer,
        private notificationService: NotificationService,
        private filesStorageService: FileStorageService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['notification']) {
            this.setYoutubeUrl()
            this.setPublicLinkImg()
        }
    }

    redirect() {
        if (!this.notification.link_url) return

        if (!this.notification.link_open)
            this.notificationService.markLinkAsOpen(this.notification).subscribe(() => {
                this.notification.link_open = true
            })

        window.open(this.notification.link_url, '_blank')
    }

    parseUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }

    setYoutubeUrl() {
        this.youtubeUrl = this.notification?.youtube_id
            ? this.parseUrl(`https://www.youtube.com/embed/${this.notification.youtube_id}`)
            : null
    }

    setPublicLinkImg() {
        if (this.notification.link_img)
            this.filesStorageService
                .getFileTemporaryUrl('detailedNotificationImage', this.notification.link_img)
                .subscribe(data => {
                    if (data.success) this.publicLinkImg = data.publicFileUrl
                })

        if (this.notification.link_small_img)
            this.filesStorageService
                .getFileTemporaryUrl('detailedNotificationImage', this.notification.link_small_img)
                .subscribe(data => {
                    if (data.success) this.publicLinkImg = data.publicFileUrl
                })
    }
}
