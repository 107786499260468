import { Component } from '@angular/core'
import { ThemeSwitcherService } from '../../services/theme-switcher.service'

@Component({
    selector: 'app-theme-switcher',
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent {
    constructor(public service: ThemeSwitcherService) {}

    toggleDarkMode() {
        this.service.toggleTheme()
    }
}
