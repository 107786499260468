import { Directive, Input } from '@angular/core'
import { DropDownDirective } from './dropdown.directive'

@Directive({
    selector: '[appNavDropdown]',
})
export class NavDropDown extends DropDownDirective {
    @Input() set closeOnRouteEvents(value: any) {
        if (value) {
            const routeSubscription = this.router.events.subscribe(() => {
                this.hide()
            })

            this.subscriptions.push(routeSubscription)
        }
    }
}
