import { fromEvent } from 'rxjs'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { Session } from '../../authentication/services/Session'
import { environment } from '../../../../environments/environment'
import { EventSourcePolyfill } from 'event-source-polyfill'

interface AppEvent extends MessageEvent {
    type: 'notification' | 'message' | 'error' | 'warning' | 'success'
    data: any
    created_at: string
}

@Injectable({
    providedIn: 'root',
})
export class EventService {
    constructor(private session: Session) {
        this.setSSE()
    }

    private eventSource!: EventSource

    listen(to: AppEvent['type']) {
        return fromEvent<MessageEvent>(this.eventSource, to).pipe(
            map((event: MessageEvent) => {
                return JSON.parse(event.data) as AppEvent
            }),
        )
    }

    private setSSE() {
        this.eventSource = new EventSourcePolyfill(
            `${environment.URL_BASE}/partnerhub/client-events/listen`,
            {
                headers: {
                    Authorization: `Bearer ${this.session.getJwt()}`,
                },
            },
        )
    }
}
