import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

export const checkOutsideClick = (element: Element) => {
  return fromEvent(document, 'click').pipe(
    filter((event) => {
      return !element.contains(event.target as Node);
    })
  );
};
