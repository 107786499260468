import { Subject } from 'rxjs'
import { Component, Input, numberAttribute, Output } from '@angular/core'

@Component({
    selector: 'app-notification-pagination',
    templateUrl: './notification-pagination.component.html',
    styleUrls: ['./notification-pagination.component.scss'],
})
export class NotificationPaginationComponent {
    @Input({ transform: numberAttribute }) totalPages: number = 0

    @Input({ transform: numberAttribute }) page: number = 0
    @Output() pageChange = new Subject<number>()

    @Input({ transform: numberAttribute }) registerPerPage: number = 0
    @Output() registerPerPageChange = new Subject<number>()
    pages: string[] = []

    constructor() {}

    onSelectRegisterPerPage(registerPerPage: any) {
        this.registerPerPageChange.next(Number(registerPerPage))
    }
}
