<div
    class="emd-modal"
    #modal
    (click)="close()"
    tabindex="0"
    *ngIf="isOpened"
    [class._active]="isOpened"
>
    <div class="emd-modal__content eu-px_2" style="width: fit-content; height: fit-content">
        <div
            class="emd-container app-custom-scrollbar"
            (click)="$event.stopPropagation()"
            [ngStyle]="containerStyle"
            #container
            [class._highlight_header]="highlightHeader && (showCloseButton || header || title)"
        >
            <div
                class="emd-container__header eu-border-bottom"
                style="gap: 1rem"
                *ngIf="header || title || showCloseButton"
                [ngStyle]="containerHeaderStyle"
            >
                <div class="flex-grow-1 d-flex align-items-center" [ngStyle]="titleStyle">
                    <h1 *ngIf="title" class="emd-text _size_section _type_bold mr-1">
                        {{ title }}
                    </h1>

                    <ng-container *ngTemplateOutlet="header"></ng-container>
                </div>
                <button class="emd-btn _type_link" (click)="close()" *ngIf="showCloseButton">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div [ngClass]="containerClass">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
