import { Router } from '@angular/router'
import { Component, Input, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { RolesService } from '../../../authentication/services/roles.service'
import { ManageLoggedUser } from '../../../authentication/services/manage-logged-user.service'
import { ConfirmModal } from '../confirm-modal/confirm-modal.component'

@Component({
    selector: 'app-account-info',
    templateUrl: './account-info.component.html',
    styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
    @Input() positionOnRight = true

    userName: string = ''

    constructor(
        public rolesService: RolesService,
        public manageLoggedUser: ManageLoggedUser,
        private modalService: NgbModal,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.userName = this.manageLoggedUser.getLoggedAccountUser().loggedUser.account_user_name
    }

    logout() {
        const openConfirmModal = this.modalService.open(ConfirmModal, {
            size: 'sm',
        })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo nos deixar?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) this.manageLoggedUser.removeLoggedAccountUser()
            this.router.navigate(['/']).then(() => {
                window.location.reload()
            })
        })
    }
}
