import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { AccessService } from '../services/access-service.service'

@Directive({
    selector: '[canView],[canNotView],[canUse]',
})
export class AccessDirectives {
    constructor(
        private accessService: AccessService,
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) {}

    @Input()
    set canView(rolesToBeChecked: string[]) {
        this.viewContainer.clear()

        if (
            this.accessService.hasRoles(rolesToBeChecked) ||
            rolesToBeChecked.includes('basic-user')
        ) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }

    @Input()
    set canNotView(rolesToBeChecked: string[]) {
        this.viewContainer.clear()

        if (!this.accessService.hasRoles(rolesToBeChecked)) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }

    @Input()
    set canUse(condition: boolean) {
        this.viewContainer.clear()

        if (condition) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }
}
