import { Directive, ElementRef, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subscription, Subject, fromEvent } from 'rxjs'
import { Router } from '@angular/router'
import { checkOutsideClick } from './check-outside-click'

const ACTIVE_CLASS_NAME = '_active'

@Directive({
    selector: '[appDropdown]',
})
export class DropDownDirective implements OnInit, OnDestroy {
    private isOpened = false

    subscriptions: Subscription[] = []

    listenOutside!: Subscription

    @Input() set appDropdownContent(content: HTMLElement) {
        this.content = content
    }

    @Input() set appDropdownTrigger(trigger: HTMLElement) {
        this.trigger = trigger
    }

    @Input() set opened(value: boolean) {
        setTimeout(() => {
            if (value && !this.isOpened) this.show()

            if (!value && this.isOpened) this.hide()
        })
    }

    @Output() openedChange = new Subject<boolean>()

    @Input() disabled: boolean = false
    @Output() open = new Subject<void>()
    @Output() close = new Subject<void>()

    content!: HTMLElement
    trigger!: HTMLElement

    constructor(
        protected elementRef: ElementRef,
        protected router: Router,
    ) {}

    ngOnInit(): void {
        if (this.trigger) {
            this.setTrigger()
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
        this.listenOutside?.unsubscribe()
    }

    setTrigger() {
        const triggerSubscription = fromEvent(this.trigger, 'click').subscribe(() => {
            if (this.disabled) return
            if (this.isOpened) return this.hide()

            return this.show()
        })

        this.subscriptions.push(triggerSubscription)
    }

    show() {
        setTimeout(() => {
            this.listenOutside = checkOutsideClick(this.elementRef.nativeElement).subscribe(
                this.hide.bind(this),
            )
        })
        this.isOpened = true
        this.openedChange.next(true)
        this.content.classList.add(ACTIVE_CLASS_NAME)
        this.open.next()
    }

    hide() {
        setTimeout(() => {
            this.listenOutside?.unsubscribe()
        })
        this.openedChange.next(false)
        this.close.next()
        this.content.classList.remove(ACTIVE_CLASS_NAME)
        this.isOpened = false
    }
}
