<div class="eu-display_flex" style="width: 100%">
    <div class="emd-container" style="width: 100%">
        <div class="emd-container__header">
            <h1 class="emd-text _size_page _color_default">Você não está autorizado</h1>
            <i class="fa fa-exclamation-circle fa-lg" aria-hidden="true"></i>
        </div>
        <div class="emd-container__content">
            <span class="emd-text _size_subpage _color_default">
                Você tentou acessar uma página para a qual não tinha autorização prévia.</span
            >
        </div>
    </div>
</div>
