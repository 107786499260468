<div
  class="emd-dropdown"
  appDropdown
  [class._position_right]="positionOnRight"
  [appDropdownContent]="content"
  [appDropdownTrigger]="trigger"
>
  <div class="emd-dropdown__trigger" #trigger>
    <a class="emd-action-button _has-tooltip" data-tooltip="Usuário">
      <i class="emd-action-button__icon fas fa fa-user"></i>
    </a>
  </div>

  <div class="emd-dropdown__content eu-border" #content>
    <div class="emd-container">
      <div class="emd-container__header eu-border-bottom">
        <span class="emd-text _weight_bold _size_section">
          {{ userName }}
        </span>

        <span class="emd-badge _color_tertiary">
          {{ rolesService.userLabel }}
        </span>
      </div>

      <div class="emd-nav-list">
        <ul>
          <li class="emd-nav-list__item w-100">
            <a
              class="emd-nav-list__link emd-text _size_section _type_normal eu-pr_6 text-decoration-none"
              [routerLink]="'logged-user'"
            >
              <i class="eu-mr_2 fa fa-user emd-text"></i>
              <span class="emd-text _color_default">Minha conta</span>
            </a>
          </li>

          <li class="emd-nav-list__item w-100">
            <a
              class="eu-pr_6 emd-nav-list__link emd-text _size_section _type_normal app-close-button"
              (click)="logout()"
            >
              <i class="eu-mr_2 emd-text fa fa-times _color_danger"></i>
              <span class="emd-text _color_danger">Sair</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
