<div class="emd-container app-notification-container d-flex flex-column">
    <div class="emd-container__header eu-border-bottom h-100">
        <div class="emd-text _size_section _type_bold">Notificações</div>
    </div>
    <div class="d-flex flex-column justify-content-between flex-grow-1">
        <ul style="min-width: 1rem">
            <li *ngFor="let notification of notifications">
                <div
                    class="app-notification emd-container _actionable d-flex justify-content-start p-3 eu-border-bottom"
                    (click)="notificationActions(notification)"
                    [class._open]="notification.open"
                >
                    <div class="d-flex align-items-center">
                        <img
                            [src]="notification.link_small_img || 'assets/icons/releases.svg'"
                            style="width: 3rem"
                        />
                    </div>

                    <div class="w-100 ml-3">
                        <div class="d-flex justify-content-between">
                            <h1 class="_type_bold emd-text _size_subpage app-title">
                                {{ notification.title }}
                            </h1>
                            <p class="emd-text _color_subtle _size_small">
                                {{ parseTimeDiff(notification.created_at) }}
                            </p>
                        </div>
                        <div class="app-message">{{ notification.description }}</div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="emd-container _actionable eu-border-top">
            <a
                class="d-flex align-items-center justify-content-center emd-text w-100 text-decoration-none p-2"
                [routerLink]="['/notifications']"
                (click)="clickToSeeMore.next()"
            >
                Ver mais &nbsp;
                <span
                    class="app-notification-count"
                    *ngIf="seeMoreCounter && seeMoreCounter != '0'"
                >
                    {{ seeMoreCounter }}
                </span>
            </a>
        </div>
    </div>
</div>
