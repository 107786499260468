import { BehaviorSubject, combineLatest, concat, Observable, of } from 'rxjs'
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import moment from 'moment'
import { take, scan, mergeMap, map } from 'rxjs/operators'
import { NotificationService } from '../../services/notification.service'
import { Notification } from '../../types/notifications/notification-types'

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
    registerPerPage$ = new BehaviorSubject(10)
    page$: BehaviorSubject<number> = new BehaviorSubject<number>(1)

    totalPages$: Observable<number> = new Observable<number>()

    isModalOpened = false
    selectedNotification!: Notification
    notifications$!: Observable<Notification[]>

    constructor(public notificationService: NotificationService) {}

    ngOnInit(): void {
        this.setNotifications()
        this.setTotalPages()
    }

    onSelectNotification(notification: Notification) {
        if (!notification.open) this.notificationService.markAsOpen(notification).subscribe()
        this.selectedNotification = notification
        this.isModalOpened = true

        if (notification.link_url) this.openNotificationLink(notification.link_url)
    }

    openNotificationLink(url: string): void {
        window.open(url, '_blank')
    }

    parseTime(time: Date) {
        const date = moment(time)

        return `${date.format('DD/MM/YYYY')} às ${date.format('HH:mm')}`
    }

    setNotifications() {
        this.notifications$ = combineLatest([this.page$, this.registerPerPage$]).pipe(
            mergeMap(([page, registerPerPage]) => {
                return concat(of(null), this.notificationService.getHead(registerPerPage, page))
            }),
        )
    }

    setTotalPages() {
        this.totalPages$ = combineLatest([
            this.notificationService.notificationCount$,
            this.registerPerPage$,
        ]).pipe(
            map(([notificationCount, registerPerPage]) => {
                // @ts-ignore
                return Math.max(1, Math.ceil(notificationCount / registerPerPage))
            }),
        )
    }
}
