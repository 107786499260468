<div class="emd-container">
  <div class="emd-container__footer">
    <div class="emd-container__content">
      <div class="emd-select app-select" style="max-width: 8.6rem">
        <select (change)="onSelectRegisterPerPage($event)">
          <option [value]="10">10 por página</option>
          <option [value]="20">20 por página</option>
          <option [value]="30">30 por página</option>
        </select>
        <div class="emd-select__arrow"></div>
      </div>
    </div>
    <div class="emd-pagination">
      <span class="emd-text _color_default">Página {{ page }} de {{ totalPages }}</span>
      <div>
        <div class="emd-item-pagination">
          <button class="emd-btn _color_primary _type_text" [disabled]="page === 1" (click)="pageChange.next(page - 1)">
            Anterior
          </button>
          <button class="emd-btn _color_primary _type_text" [disabled]="page === totalPages"
            (click)="pageChange.next(page + 1)">
            Próxima
          </button>
        </div>
      </div>
    </div>
  </div>
</div>