<div class="d-flex justify-content-center px-2">
    <div class="flex-grow-1">
        <div class="emd-container app-notification-container">
            <div class="emd-container__header eu-border-bottom">
                <div class="emd-text _size_section _type_bold">Notificações</div>
            </div>
            <ul *ngIf="notifications$ | async as notifications; else loading">
                <li
                    *ngFor="let notification of notifications"
                    (click)="onSelectNotification(notification)"
                >
                    <div
                        class="app-notification emd-container _actionable d-flex justify-content-start p-3"
                        [class._open]="notification.open"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                [src]="notification.link_small_img || 'assets/icons/releases.svg'"
                                style="width: 3rem"
                            />
                        </div>

                        <div class="w-100 ml-3">
                            <div class="d-flex justify-content-between">
                                <h1 class="_type_bold emd-text _size_subpage app-title">
                                    {{ notification.title }}
                                </h1>
                                <p class="emd-text _color_subtle _size_small">
                                    {{ parseTime(notification.created_at) }}
                                </p>
                            </div>
                            <div class="app-message">{{ notification.description }}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mt-3">
            <app-notification-pagination
                [page]="page$ | async"
                [totalPages]="totalPages$ | async"
                (pageChange)="page$.next($event)"
                [registerPerPage]="registerPerPage$ | async"
                (registerPerPageChange)="registerPerPage$.next($event)"
            ></app-notification-pagination>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="px-3 py-5 w-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border text-black cep-spiner ml-2" role="status">
            <span class="sr-only">Loading... </span>
        </div>
    </div>
</ng-template>

<app-notification-details
    *ngIf="selectedNotification"
    [notification]="selectedNotification"
    [(opened)]="isModalOpened"
></app-notification-details>
