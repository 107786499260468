import { Notification } from '../../types/notifications/notification-types'
import { Component, Input, Output } from '@angular/core'
import { Subject } from 'rxjs'
import moment from 'moment'

@Component({
    selector: 'app-notification-box',
    templateUrl: './notification-box.component.html',
    styleUrls: ['./notification-box.component.scss'],
})
export class NotificationBoxComponent {
    @Input() notifications!: Notification[]
    @Input() seeMoreCounter: string | null = null

    @Output() selectNotification = new Subject<Notification>()
    @Output() clickToSeeMore = new Subject<void>()

    constructor() {}

    notificationActions(notification: any) {
        this.selectNotification.next(notification)
        if (notification.link_url) this.openNotificationLink(notification.link_url)
    }

    openNotificationLink(url: string): void {
        window.open(url, '_blank')
    }

    parseTimeDiff(time: Date) {
        const minutesDiff = moment().diff(moment(time), 'minutes')

        if (minutesDiff < 1) return 'agora'

        if (minutesDiff < 60) return `há ${minutesDiff} minutos`

        const hoursDiff = moment().diff(moment(time), 'hours')

        if (hoursDiff < 24) return `há ${hoursDiff} horas`

        return moment(time).format('DD/MM/YYYY')
    }
}
