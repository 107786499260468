<button id="notification-button" class="emd-btn _type_text" #trigger>
    <svg-icon
        class="notification-icon"
        [src]="'assets/icons/navigation/notification.svg'"
        [applyClass]="true"
    />
    @if (newNotificationCounter$ | async) {
        <div class="app-notification-count">
            {{ newNotificationCounter$ | async }}
        </div>
    }
</button>
<span class="emd-text notification-label" [class.expanded]="isExpanded()">Notificações</span>

<div
    class="emd-dropdown"
    [class.notification-default-position]="!isExpanded()"
    [class.notification-expanded-position]="isExpanded()"
    appDropdown
    #dropdown
    [(opened)]="isDropdownOpened"
    [appDropdownContent]="content"
    (mouseenter)="handleDropwDownMenuFocus()"
>
    <div class="emd-dropdown__content eu-border" #content>
        <app-notification-box
            (selectNotification)="selectNotification($event)"
            *ngIf="notifications$ | async as notifications; else loading"
            (clickToSeeMore)="handleNotificationAsActive()"
            [notifications]="notifications"
            [seeMoreCounter]="seeMoreCounter$ | async"
        ></app-notification-box>

        <ng-template #loading>
            <div class="px-3 py-5 w-100 d-flex justify-content-center align-items-center">
                <div class="spinner-border text-black cep-spiner ml-2" role="status">
                    <span class="sr-only">Loading... </span>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<app-emd-modal
    #modal
    *ngIf="selectedNotification"
    [title]="selectedNotification.title"
    [(opened)]="isModalOpened"
    (openedChange)="!$event ? onNotificationClose() : null"
    [containerStyle]="{
        width: 'calc(100vw - 3rem)',
        'max-width': '1000px',
    }"
>
    <app-notification-details
        *ngIf="isModalOpened"
        [notification]="selectedNotification"
    ></app-notification-details>
</app-emd-modal>
