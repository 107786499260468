import {
    Component,
    ContentChild,
    ElementRef,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { fromEvent, Subject, Subscription } from 'rxjs'

@Component({
    selector: 'app-emd-modal',
    templateUrl: './emd-modal.component.html',
    styleUrls: ['./emd-modal.component.scss'],
})
export class EmdModalComponent {
    @Input() containerStyle: any = {}
    @Input() containerHeaderStyle: any = {}
    @Input() titleStyle: any = {}
    @Input() title?: string
    @Input() showCloseButton = true
    @Input() highlightHeader = false
    @Input() containerClass = ['emd-container__content']

    isOpened = false

    @Input() set opened(value: boolean) {
        if (value) this.open()

        if (!value) this.close()
    }
    get opened() {
        return this.isOpened
    }

    @Output() openedChange = new Subject<boolean>()
    @Output() show = new Subject<boolean>()
    @Output() hide = new Subject<boolean>()

    @ViewChild('modal') modal!: ElementRef<HTMLElement>
    @ViewChild('container') container!: ElementRef<HTMLElement>

    @ContentChild('header') header!: TemplateRef<HTMLElement>

    private subscriptions: Subscription[] = []

    constructor() {}

    ngOnInit() {
        const subscription = fromEvent(document, 'keydown').subscribe(event => {
            this.onKeyDown(event as KeyboardEvent)
        })

        this.subscriptions.push(subscription)
    }

    ngOnDestroy() {
        this.subscriptions?.forEach(subscription => subscription.unsubscribe())
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') this.close()
    }

    toggle() {
        if (this.isOpened) this.close()
        else this.open()
    }

    open() {
        if (!this.isOpened) {
            this.openedChange.next(true)
            this.isOpened = true
            this.show.next(true)
        }
    }

    close() {
        if (this.isOpened) {
            this.openedChange.next(false)
            this.isOpened = false
            this.hide.next(true)
        }
    }
}
