<div class="app-content-container">
    <a
        class="app-image"
        [class._has_link]="notification.link_url"
        *ngIf="notification.link_img"
        (click)="redirect()"
        [class._take_full_width]="!notification.long_description"
    >
        <img [src]="publicLinkImg" alt="Imagem Banner da Notificação" />
    </a>
    <markdown *ngIf="notification.long_description" [data]="notification.long_description">
    </markdown>
</div>

<div class="d-flex justify-content-center align-items-center" style="gap: 1rem">
    <div class="iframe-container loading" #iframeContainer *ngIf="youtubeUrl">
        <div class="app-loading-container">
            <div class="spinner-border text-black cep-spiner app-loading-iframe" role="status">
                <span class="sr-only">Loading... </span>
            </div>
        </div>

        <div class="app-error-container">
            <span class="text-danger">
                <i class="fa fa-exclamation-triangle"></i>
                Não foi possível carregar o vídeo
            </span>
        </div>

        <iframe
            class="app-youtube-iframe"
            [src]="youtubeUrl"
            (load)="iframeContainer.classList.remove('loading')"
            (error)="
                iframeContainer.classList.remove('loading'); iframeContainer.classList.add('error')
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    </div>
</div>
