import { Notification } from '../../types/notifications/notification-types'
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    input,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { NotificationService } from '../../services/notification.service'
import { map, take, takeUntil } from 'rxjs/operators'
import { NotificationPopUpService } from '../../services/notification-pop-up.service'
import { Router } from '@angular/router'
import { NavigationBarService } from '../../../../features/navigation/services/navigation-bar.service'

@Component({
    selector: 'app-notification-manager',
    templateUrl: './notification-manager.component.html',
    styleUrls: ['./notification-manager.component.scss'],
    host: {
        '[class.expanded-nav]': 'isExpanded()',
        '[class.simple-nav]': '!isExpanded()',
        '[class.active]': 'isActive()',
    },
})
export class NotificationManagerComponent implements OnInit, OnDestroy {
    isExpanded = input<boolean>(false)
    isActive = signal(false)
    notifications$!: Observable<Notification[]>
    seeMoreCounter$!: Observable<string>
    newNotificationCounter$!: Observable<string>

    selectedNotification: Notification | null = null

    isDropdownOpened = false

    isModalOpened: boolean = false

    private _onDestroy$ = new Subject()

    constructor(
        public notificationService: NotificationService,
        private router: Router,
        private notificationPopUpService: NotificationPopUpService,
        private navBarService: NavigationBarService,
    ) {}

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }

    ngOnInit(): void {
        this.setNotifications()
        this.notificationPopUpService.listen()

        this.notificationPopUpService.clickPopUp$.subscribe(notification => {
            this.selectNotification(notification)
        })

        this.observeWhenSimplifiedNavigationFocusedNode()
        this.observeActiveRootNode()
    }

    setNotifications() {
        const NOTIFICATION_COUNT = 5

        this.notifications$ = this.notificationService.getHead(NOTIFICATION_COUNT)

        // @ts-ignore
        this.notifications$.pipe(take(1)).subscribe(notifications => {
            for (const notification of notifications)
                if (notification.open_is_automatic && notification.status === 'unread')
                    return this.selectNotification(notification)
        })

        // @ts-ignore
        this.seeMoreCounter$ = this.notificationService.unreadCount$.pipe(
            map(count => {
                if (count) {
                    const counter = count - NOTIFICATION_COUNT
                    return this.parseNotificationCounter(counter)
                }
                return
            }),
        )

        // @ts-ignore
        this.newNotificationCounter$ = this.notificationService.unreadCount$.pipe(
            map(this.parseNotificationCounter),
        )
    }

    parseNotificationCounter(count: any) {
        if (count <= 0) return null
        if (count > 99) return '+99'

        return String(count)
    }

    // @ts-ignore
    selectNotification(notification: Notification) {
        if (!notification.open) this.notificationService.markAsOpen(notification).subscribe()

        this.selectedNotification = notification

        if (notification.type == '1' && notification.link_targeting) {
            return this.router.navigate([notification.link_targeting])
        }

        if (notification.type == '1') return

        this.isModalOpened = true
        this.isDropdownOpened = false
    }

    onNotificationClose() {
        this.selectedNotification = null
    }

    handleDropwDownMenuFocus() {
        if (!this.isExpanded()) {
            this.navBarService.stopFocusedNodeTimeout()
        }
    }

    private observeWhenSimplifiedNavigationFocusedNode() {
        this.navBarService.focusedRootNode.pipe(takeUntil(this._onDestroy$)).subscribe(node => {
            if (node && node.label === 'Notificação') {
                this.isDropdownOpened = true
            } else {
                this.isDropdownOpened = false
            }
        })
    }

    private observeActiveRootNode() {
        this.navBarService.activeNode.pipe(takeUntil(this._onDestroy$)).subscribe(activeNode => {
            if (activeNode) {
                this.isActive.set(false)
            }
        })
    }

    handleNotificationAsActive() {
        this.isDropdownOpened = false
        this.navBarService.setActiveNode(null)

        this.isActive.set(true)
    }
}
