import { Notification } from '../types/notifications/notification-types'
import { IndividualConfig, ToastrService } from 'ngx-toastr'
import { NotificationService } from './notification.service'
import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class NotificationPopUpService {
    clickPopUp$ = new Subject<Notification>()

    constructor(
        private notificationService: NotificationService,
        public toastrService: ToastrService,
    ) {}

    listen() {
        this.notificationService.push$.subscribe(notification => {
            const toast = this.show(notification)

            toast.onTap.subscribe(() => {
                this.clickPopUp$.next(notification)
            })
        })
    }

    show(notification: Notification, options?: Partial<IndividualConfig>) {
        return this.toastrService.show(notification.description, notification.title, {
            easeTime: 200,
            timeOut: 3000,
            toastClass: 'ngx-toastr app-notification-toast',
            positionClass: 'toast-top-center',
            ...options,
        })
    }
}
