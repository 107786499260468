import { EventService } from './event.service'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators'
import {
    getNotificationByUserEmailResponse,
    Notification,
} from '../types/notifications/notification-types'
import { environment } from '../../../../environments/environment'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { HttpClientOptionsService } from '../../authentication/services/http-client-options.service'

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    push$!: Observable<Notification>
    private notificationsChange$ = new Subject<void>()

    private _notificationCount$ = new BehaviorSubject<number | null>(null)

    get notificationCount$() {
        return this._notificationCount$.asObservable().pipe(filter(value => value != null))
    }

    private _unreadCount$ = new BehaviorSubject<number | null>(null)

    get unreadCount$() {
        return this._unreadCount$.asObservable().pipe(filter(value => value != null))
    }

    constructor(
        private eventService: EventService,
        private httpClient: HttpClient,
        private httpClientOptionsService: HttpClientOptionsService,
    ) {
        this.setPush()
    }

    private setPush() {
        this.push$ = this.eventService.listen('notification').pipe(
            map(event => {
                this.notificationsChange$.next()
                return event.data.notification
            }),
        )
    }

    getHead(count = 5, page = 1) {
        const request$ = this.requestNotifications(count, page).pipe(
            map(response => {
                this._notificationCount$.next(response.recordCount)
                this._unreadCount$.next(response.totalUnread)

                return response.notifications
            }),
        )

        return this.notificationsChange$.pipe(
            startWith(true),
            switchMap(() => request$),
        )
    }

    markAsRead(notification: Notification) {
        return this.httpClient
            .post(
                `${environment.URL_BASE}/partnerhub/notifications/mark-as-read`,
                {
                    notification_ids: [notification._id],
                },
                this.httpClientOptionsService.getPartnerhubAuthorization(),
            )
            .pipe(
                tap(() => {
                    this.notificationsChange$.next()
                }),
            )
    }

    markAsOpen(notification: Notification) {
        return this.httpClient
            .post(
                `${environment.URL_BASE}/partnerhub/notifications/mark-as-open`,
                {
                    notification_ids: [notification._id],
                },
                this.httpClientOptionsService.getPartnerhubAuthorization(),
            )
            .pipe(
                tap(() => {
                    this.notificationsChange$.next()
                }),
            )
    }

    markLinkAsOpen(notification: Notification) {
        return this.httpClient
            .post(
                `${environment.URL_BASE}/partnerhub/notifications/mark-link-as-open`,
                {
                    notification_ids: [notification._id],
                },
                this.httpClientOptionsService.getPartnerhubAuthorization(),
            )
            .pipe(
                tap(() => {
                    this.notificationsChange$.next()
                }),
            )
    }

    requestNotifications(registersPerPage: number, pageNumber: number) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/notifications/query-pagination`,
            {
                registersPerPage,
                pageNumber,
            },
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    deleteNotificationFromUser(userId: string, notificationId: string) {
        return this.httpClient.delete(
            `${environment.URL_BASE}/partnerhub/notifications/user-notification/${userId}/${notificationId}`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    requestNotificationByUserEmail(email: string, pageNumber: number, registersPerPage: number) {
        return this.httpClient
            .post<getNotificationByUserEmailResponse>(
                `${environment.URL_BASE}/partnerhub/notifications/query-pagination-user-email`,
                {
                    pageNumber,
                    registersPerPage,
                    email,
                },
                this.httpClientOptionsService.getPartnerhubAuthorization(),
            )
            .pipe()
    }
}
