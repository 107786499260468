<div class="emd-mode-switcher">
  <input
    class="emd-mode-switcher__input"
    [checked]="service.isDarkModeEnabled$ | async"
    (change)="toggleDarkMode()"
    type="checkbox"
    id="modeToggle"
  />
  <label class="emd-mode-switcher__toggle" for="modeToggle">
    <span class="emd-mode-switcher__left-text">AM</span>
    <div class="emd-mode-switcher__area">
      <div class="emd-mode-switcher__indicator">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <span class="emd-mode-switcher__right-text">PM</span>
  </label>
</div>
