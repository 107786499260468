import { Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { Permission, Role, RolesDTO, UserTypeName } from '../types/AuthenticationTypes'
import { AuthenticationService } from './authentication.service'

/**
 * Este serviço abstrai o sistema de permissão do PartnerHub
 */

@Injectable({ providedIn: 'root' })
export class RolesService {
    protected userPermissionsMap = new Map<Role, Permission[]>()
    constructor(private authenticationService: AuthenticationService) {}

    userType!: UserTypeName

    loaded = false

    load() {
        return this.roles$.pipe(
            tap((roles: RolesDTO[]) => {
                this.loaded = true
                this.userPermissionsMap.set('basic-access', [])

                for (const role of roles) {
                    if (role.type === 'role-type') {
                        this.userType = role.value as UserTypeName
                        continue
                    }

                    this.userPermissionsMap.set(
                        role.type,
                        String(role.value).split('&') as Permission[],
                    )
                }
            }),
        )
    }

    get roles$() {
        return this.authenticationService.getAccountUserPermissions().pipe(
            map((data: any) => {
                return data.account_user_roles.roles
            }),
        )
    }

    get userLabel() {
        switch (this.userType) {
            case 'user-admin-app':
                return 'Administrador'

            case 'user-bizdev':
                return 'Bizdev'

            case 'user-sales-person':
                return 'Lojista'

            case 'user-admin-partner':
                return 'Parceiro'
            default:
                return 'usuário'
        }
    }

    /**
     * Use essa função para checkar se
     *o usuário tem tanto uma role quanto uma certa permissão relacionada a esta role */
    userCan(permission: Permission, roleName: Role) {
        const role = this.userPermissionsMap.get(roleName)
        return Boolean(role && role.includes(permission))
    }

    /**
     * Use essa função para checkar se o usuário tem a role `roleName`
     */

    userHasRole(roleName: Role) {
        return this.userPermissionsMap.has(roleName)
    }

    userHasRoles(roles: Role[]) {
        return roles.reduce((value, role) => value && this.userHasRole(role), true)
    }

    userIs(...possibleUsers: UserTypeName[]) {
        return possibleUsers.includes(this.userType) || possibleUsers.includes('basic-user')
    }
}
