import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { EmdModalComponent } from './components/emd-modal/emd-modal.component'
import { NotificationManagerComponent } from './components/notification-manager/notification-manager.component'
import { NotificationBoxComponent } from './components/notification-box/notification-box.component'
import { AccountInfoComponent } from './components/account-info/account-info.component'
import { ConfirmModal } from './components/confirm-modal/confirm-modal.component'
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component'
import { NavDropDown } from './directives/navdropdown.directive'
import { DropDownDirective } from './directives/dropdown.directive'
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component'
import { MarkdownModule } from 'ngx-markdown'
import { NotificationListComponent } from './components/notification-list/notification-list.component'
import { NotificationPaginationComponent } from './components/notification-pagination/notification-pagination.component'
import { AccessDirectives } from './directives/can-access.directive'
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component'
import { AngularSvgIconModule } from 'angular-svg-icon'

@NgModule({
    declarations: [
        EmdModalComponent,
        NotificationManagerComponent,
        NotificationBoxComponent,
        AccountInfoComponent,
        ConfirmModal,
        ThemeSwitcherComponent,
        NavDropDown,
        DropDownDirective,
        NotificationDetailsComponent,
        NotificationListComponent,
        NotificationPaginationComponent,
        AccessDirectives,
        NotAuthorizedComponent,
    ],
    imports: [CommonModule, RouterModule, MarkdownModule.forRoot(), AngularSvgIconModule.forRoot()],
    exports: [
        EmdModalComponent,
        ConfirmModal,
        NotificationDetailsComponent,
        NotificationListComponent,
        AccessDirectives,
        NotAuthorizedComponent,
        NotificationBoxComponent,
        NotificationManagerComponent,
    ],
})
export class CoreModule {}
